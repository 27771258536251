import {Styled} from "./EventImages.styles"
import PageHolder from "../../lib/UI/PageHolder";

const EventImages = () => {

    const events_list = [
        {
            title: 'ACL1',
            link: 'https://ashigarami.pixieset.com/acl/',
            image: window.location.origin + "/zdj-1-strona-glowna.jpg"
        },
    ]

    return (
        <PageHolder>
            <Styled.Event__Images__Page>
                {events_list.map((item: any) => (
                    <Styled.Event__Option
                        href={item.link}
                        target={'_blank'}
                    >
                        <Styled.Event__Image
                            src={item.image}
                        />
                        <Styled.Event__Title__Holder>
                            <Styled.Event__Title>
                                {item.title}
                            </Styled.Event__Title>
                        </Styled.Event__Title__Holder>
                    </Styled.Event__Option>
                ))}

            </Styled.Event__Images__Page>
        </PageHolder>
    )
}

export default EventImages