import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import {rgba} from "polished";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../../lib/UI/Text";

const Message__Form__Holder = styled(FlexBox)`
  position: relative;
  flex-direction: column;
  max-width: 800px;
  width: calc(100% - 20px);
  padding: 0 10px;
  margin: 0 auto;
`
const Status__Holder = styled(FlexBox)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.6)};
`
const Loader__Icon = styled(FontAwesomeIcon)`
  @keyframes skeletonColor {
    0% {
      color: ${({theme}) => rgba(theme.color, 0.5)}
    }
    50% {
      color: ${({theme}) => rgba(theme.color, 0.1)}
    }
    100% {
      color: ${({theme}) => rgba(theme.color, 0.5)}
    }
  }
  height: 45px;
  width: 45px;
  margin: auto;
  animation: 2s linear infinite skeletonColor;
  color: ${({theme}) => theme.color};
`
const OK__Status__Icon = styled(FontAwesomeIcon)`
  height: 45px;
  width: 45px;
  color: #10b410;
  margin: 0 auto;
`
const Status__Holder__Icon = styled(FlexBox)`
  flex-direction: column;
  margin: auto;
`
const OK__Status__Text = styled(Text)`
  font-size: 19px;
  font-weight: bold;
  margin-top: 8px;
`

export const Styled = {
    OK__Status__Text,
    Status__Holder__Icon,
    OK__Status__Icon,
    Status__Holder,
    Message__Form__Holder,
    Loader__Icon
}