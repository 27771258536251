import {Styled} from "./Header.styles"
import {useNavigate} from "react-router-dom";
import usePageContent from "../../lib/PageContent";


type DropDownProps = {
    list: any[]
}

const NavigationToolTipContents = (
    {
        list
    }: DropDownProps) => {

    const {handleAnimation,} = usePageContent()

    const navigate = useNavigate()

    return (
        <Styled.Navigation__Drop__Down>
            {list.map((option: any) => (
                <Styled.Navigation__Drop__Down__Option
                    onClick={option?.action ? option.action : () => {
                        handleAnimation()
                        setTimeout(() => {
                            navigate(option.navigation)
                            window.scroll(0, 0)
                        }, 400)
                    }}>
                    <Styled.Navigation__Drop__Down__Icon
                        icon={option.icon}
                    />
                    {option.title}
                </Styled.Navigation__Drop__Down__Option>
            ))}
        </Styled.Navigation__Drop__Down>
    )
}

export default NavigationToolTipContents