import {Styled} from "./MainPageNewsSeller.styles"

type NewsProps = {
    item: any
}


const News = (
    {
        item
    }: NewsProps
) => {

    const {
        image,
        title,
        content,
        link,
    } = item

    const onClick = () => {
        if (link) {
            window.open(link, '_blank')
        }
    }

    return (
        <Styled.News__Component
            onClick={onClick}
        >
            <Styled.News__Image
                src={image}
            />
            <Styled.News__Title>
                {title}
            </Styled.News__Title>
            <Styled.News__Content__Text>
                {content}
            </Styled.News__Content__Text>
        </Styled.News__Component>
    )
}

export default News