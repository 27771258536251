import styled from "styled-components";
import Text from "../../lib/UI/Text";
import FlexBox from "../../lib/UI/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Join_Us = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  padding: 25px 10px;
  text-align: center;
`
const Invite__Text = styled(Text)`
  font-size: 18px;
  padding: 0 10px;
`
const Join__Us__Holder = styled(FlexBox)`
  flex-direction: column;
  min-height: calc(100vh - 57px);
`
const How__To__Join = styled(Text)`
  font-size: 22px;
  font-weight: bold;
  padding: 25px 10px;
  text-align: center;
`
const How__To__Join__Go__To__Tab = styled(Text)`
  font-size: 18px;
  padding: 0 10px;
`
const Join__Us_Write__Email = styled(Text)`
  font-size: 22px;
  padding: 0 10px;
  text-align: center;
`

const Mail__Holder = styled.a`
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  cursor: pointer;
  color: ${({theme}) => theme.color};

  &:hover {
    text-decoration: underline;
  }
`
const Mail__Icon = styled(FontAwesomeIcon)`
  height: 22px;
  width: 22px;
  margin-right: 8px;
`
const Kontakt__Holder = styled(Text)`
  margin: 0 auto;
  font-size: 20px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`


export const Styled = {
    Kontakt__Holder,
    Mail__Icon,
    Mail__Holder,
    Join__Us_Write__Email,
    How__To__Join__Go__To__Tab,
    How__To__Join,
    Join__Us__Holder,
    Invite__Text,
    Join_Us
}