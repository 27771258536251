import styled from "styled-components";
import Text from "../../lib/UI/Text";
import FlexBox from "../../lib/UI/FlexBox";
import Image from "../../lib/UI/Image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Sponsors__Title = styled(Text)<{ customSize: string | undefined }>`
  font-size: ${({customSize}) => customSize ?? '26px'};
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
`
const Sponsor__Content__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Sponsors__Holder = styled(FlexBox)`
  justify-content: center;
  gap: 15px;
  padding: 10px 0;
`
const Sponsor__Image = styled(Image)<{ sizeHeight: string }>`
  height: ${({sizeHeight}) => sizeHeight};
  background-color: transparent;
  object-fit: contain;
  cursor: pointer;
`
const Sponsor__Section__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 15px 0;
  margin: 50px 0;
`
const Sponsor__Section__Style = styled(FlexBox)<{ isWholeScreen: boolean }>`
  width: 100%;
  min-height: ${({isWholeScreen}) => isWholeScreen && 'calc(100vh - 58px)'};
`
const Sponsors__Section__Title = styled(Text)`
  font-size: 29px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
`
const Become__Section = styled(FlexBox)`
  flex-direction: column;
  margin-top: 30px;
`
const Contact__Page__Options__Holder = styled.a`
  width: calc(100% - 20px);
  font-size: 24px;
  color: ${({theme}) => theme.color};
  cursor: pointer;
  padding: 0 10px;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`
const Contact__Page__Type__Icon = styled(FontAwesomeIcon)`
  height: 20px;
  width: 20px;

  margin-right: 10px;
`
const Become__Sponsor__Title = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
  text-align: center;
`
const Sponsor__Section__Desc = styled(Text)`
  font-size: 19px;
`
const Terms__Option = styled.a`
  display: flex;
  text-decoration: none;
  box-sizing: border-box;
  padding: 25px;
  border-radius: 5px;
  margin: 0 auto;
  max-width: 400px;
  border: 1px solid ${({theme}) => theme.color};

`

const Terms__Title = styled(Text)`
  font-weight: bold;
  font-size: 29px;
  text-align: center;
  padding: 20px 10px;
`
const Pdf__Icon = styled(FontAwesomeIcon)`
  height: 25px;
  width: 25px;
  margin: auto 0;
  color: ${({theme}) => theme.color};
`
const Pdf__Title = styled(Text)`
  font-size: 22px;
  margin-left: 15px;
`


export const Styled = {
    Terms__Option,
    Terms__Title,
    Pdf__Icon,
    Pdf__Title,
    Sponsor__Section__Desc,
    Become__Sponsor__Title,
    Contact__Page__Options__Holder,
    Contact__Page__Type__Icon,
    Become__Section,
    Sponsors__Section__Title,
    Sponsor__Section__Style,
    Sponsors__Holder,
    Sponsor__Section__Holder,
    Sponsor__Image,
    Sponsors__Title,
    Sponsor__Content__Holder
}