import PageHolder from "../../lib/UI/PageHolder";
import {Styled} from "./Sponsors.styles";
import {useTranslation} from "react-i18next";
import {isEmpty} from "lodash-es";
import {faEnvelope, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import usePageContent from "../../lib/PageContent";
import MessageForm from "../../Components/MessageForm";

type SponsorsProps = {
    isMainPage?: boolean
}

const Sponsors = (
    {
        isMainPage
    }: SponsorsProps
) => {

    const {t} = useTranslation()

    const {sponsorsData, sponsorMail} = usePageContent()


    const customSizes: any = {
        gold: isMainPage ? '80px' : '110px',
        silver: isMainPage ? '70px' : '100px',
        bronze: isMainPage ? '60px' : '90px',
        other: isMainPage ? "65px" : '85px'
    }

    return (
        <Styled.Sponsor__Section__Style
            isWholeScreen={!isMainPage}
        >
            <PageHolder>
                <Styled.Sponsor__Section__Holder>
                    {!isMainPage && (
                        <>
                            <Styled.Sponsors__Section__Title>
                                {t('lbl_become_sponsor').split('<BREAK>').map((content: string) => (<>{content}<br/><br/></>))}
                            </Styled.Sponsors__Section__Title>
                            <Styled.Sponsor__Section__Desc>
                                {t('lbl_become_sponsor_desc').split('<BREAK>').map((content: string) => (<>{content}<br/><br/></>))}
                            </Styled.Sponsor__Section__Desc>
                        </>
                    )}
                    {!isMainPage && (
                        <>
                            <Styled.Sponsors__Section__Title>
                                {t('lbl_sponsor_section_title')}
                            </Styled.Sponsors__Section__Title>
                            <Styled.Terms__Option
                                href={window.location.origin + '/Acl2 oferta sponsorska.pdf'}
                                download={true}
                            >
                                <Styled.Pdf__Icon
                                    icon={faFilePdf}
                                />
                                <Styled.Pdf__Title>
                                    {t('lbl_sponsor_offer')}
                                </Styled.Pdf__Title>
                            </Styled.Terms__Option>
                        </>
                    )}

                    {!isMainPage && (
                        <Styled.Become__Section>
                            <Styled.Sponsors__Section__Title>
                                {t('lbl_become_sponsor_or_partner')}
                            </Styled.Sponsors__Section__Title>
                            <Styled.Contact__Page__Options__Holder
                                href={`mailto:${sponsorMail}`}
                            >
                                <Styled.Contact__Page__Type__Icon
                                    icon={faEnvelope}
                                />
                                {sponsorMail}
                            </Styled.Contact__Page__Options__Holder>
                            <br/>
                            <br/>
                            <MessageForm/>
                        </Styled.Become__Section>
                    )}
                    {Object.keys(sponsorsData).map((sponsorType: string) => {

                        const sponsorsList = sponsorsData[sponsorType]

                        const getSizesForFont = sponsorType === 'other' ? '20px' : undefined

                        return !isEmpty(sponsorsList) ? (
                            <Styled.Sponsor__Content__Holder>
                                <Styled.Sponsors__Title
                                    customSize={getSizesForFont}
                                >
                                    {t(`lbl_${sponsorType}_sponsor`)}
                                </Styled.Sponsors__Title>
                                <Styled.Sponsors__Holder>
                                    {sponsorsList.map((sponsor: any) => (
                                        <Styled.Sponsor__Image
                                            sizeHeight={customSizes[sponsorType]}
                                            src={sponsor.image}
                                        />
                                    ))}
                                </Styled.Sponsors__Holder>
                            </Styled.Sponsor__Content__Holder>

                        ) : null
                    })}
                </Styled.Sponsor__Section__Holder>
            </PageHolder>
        </Styled.Sponsor__Section__Style>
    )
}
export default Sponsors