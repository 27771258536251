import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Text from "../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Ranking__Holder = styled(FlexBox)`
  flex-direction: column;
  height: calc(100vh - 84px);
`
const Ranking__Coming__Soon__Title = styled(Text)`
  font-size: 26px;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  margin: 0 auto auto auto;
`
const Ranking__Icon = styled(FontAwesomeIcon)`
  height: 60px;
  width: 60px;
  margin: auto auto 5px auto;
  color: ${({theme}) => theme.color};
`


export const Styled = {
    Ranking__Holder,
    Ranking__Icon,
    Ranking__Coming__Soon__Title
}