import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Image from "../../lib/UI/Image";
import Text from "../../lib/UI/Text";

const Our__League__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
`
const League__Image = styled(Image)`
  width: 100%;
  height: 550px;
  @media (max-width: 1110px) {
    height: 50vw;
  }
`
const Our__League__Title = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  padding: 25px 10px;
  text-align: center;
`
const Our__league__Description = styled(Text)`
  font-size: 18px;
  padding: 0 10px;
  padding-bottom: 20px;
`
const Our__League__Location = styled(Text)`
  font-size: 19px;
  font-weight: bold;
`
const Our__League__Whats__Sets_Us__Apart = styled(Text)`
  font-size: 21px;
  font-weight: bold;
  padding: 15px 10px;
`
const League__Section__Title = styled(Text)`
  font-size: 19px;
  font-weight: bold;
  padding: 0 10px;
`
const League__Section__Answer = styled(Text)`
  padding: 0 10px;
  font-size: 19px;
`
const Our__League__Reasons__Holder = styled(FlexBox)`
  flex-direction: column;
  padding-bottom: 30px;
`


export const Styled = {
    Our__League__Reasons__Holder,
    League__Section__Answer,
    League__Section__Title,
    Our__League__Whats__Sets_Us__Apart,
    Our__League__Location,
    League__Image,
    Our__League__Title,
    Our__League__Holder,
    Our__league__Description
}