import {Styled} from "./OurTeam.styles"

type PersonProps = {
    data: any
}

const Person = (
    {
        data
    }: PersonProps
) => {

    const {
        image,
        name,
        title,
        description,

    } = data

    return (
        <Styled.Person__Box>
            <Styled.Person__Image
                src={image}
            />
            <Styled.Person__Information__Holder>
                <Styled.Person__Name>
                    {name}
                </Styled.Person__Name>
                <Styled.Person__Title>
                    {title}
                </Styled.Person__Title>
                <Styled.Person__Description>
                    {description}
                </Styled.Person__Description>
            </Styled.Person__Information__Holder>
        </Styled.Person__Box>
    )
}

export default Person