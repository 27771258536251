import PageHolder from "../../lib/UI/PageHolder";
import {Styled} from "./OurTeam.styles";
import {useTranslation} from "react-i18next";
import Person from "./Person";
import usePageContent from "../../lib/PageContent";

const OurTeam = () => {

    const {t} = useTranslation()

    const {outTeamSections} = usePageContent()

    return (
        <PageHolder>
            <Styled.Our__Team__Section__Holder>
                <Styled.Our__Team__Section__Title>
                    {t('lbl_our_squad')}
                </Styled.Our__Team__Section__Title>
                <Styled.Team__Mates__Holder>
                    {outTeamSections.map((person: any) => {
                        return (
                            <Person
                                data={person}
                            />
                        )
                    })}
                </Styled.Team__Mates__Holder>
            </Styled.Our__Team__Section__Holder>
        </PageHolder>
    )
}

export default OurTeam