import {Styled} from "./UpcomingEvents.styles"
import {useTranslation} from "react-i18next";
import PageHolder from "../../../lib/UI/PageHolder";
import usePageContent from "../../../lib/PageContent";

const UpcomingEvents = () => {


    const {t} = useTranslation()

    const {upComingEvent, upcomingEventOptions} = usePageContent()

    const {
        eventName,
        eventData,
        eventLocation,
        eventTime,
        weightingTime,
        eventWeekDay,
    } = upComingEvent


    return (
        <Styled.Upcoming__Events__Holder>
            <PageHolder>
                <Styled.Upcoming__Event__Holder>
                    <Styled.Section__Label>
                        {eventName}
                    </Styled.Section__Label>
                    <Styled.Upcoming__Event__Date>
                        {eventWeekDay}{' '}{eventData}
                    </Styled.Upcoming__Event__Date>
                    <Styled.Upcoming__Event__Date>
                        {t('lbl_mma_start_hour')}:{' '}{eventTime}
                    </Styled.Upcoming__Event__Date>
                    <Styled.Upcoming__Event__Date>
                        {t('lbl_final_gala')}:{' '}{weightingTime}
                    </Styled.Upcoming__Event__Date>
                    <Styled.Upcoming__Event__Main__Info>
                        {eventLocation}
                    </Styled.Upcoming__Event__Main__Info>
                    <Styled.Event__Action__Buttons>
                        {upcomingEventOptions.map((item: any) => (
                            <Styled.Event__Action__Eclipse
                                onClick={item.action}
                            >
                                {item.label}
                            </Styled.Event__Action__Eclipse>
                        ))}
                    </Styled.Event__Action__Buttons>
                </Styled.Upcoming__Event__Holder>
            </PageHolder>
        </Styled.Upcoming__Events__Holder>
    )
}
export default UpcomingEvents