import styled from "styled-components";
import Text from "../../lib/UI/Text";
import FlexBox from "../../lib/UI/FlexBox";
import {rgba} from "polished";
import Image from "../../lib/UI/Image";

const Our__Team__Section__Title = styled(Text)`
  font-weight: bold;
  font-size: 29px;
  text-align: center;
`
const Our__Team__Section__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 20px 10px;
`
const Team__Mates__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-top: 25px;
  gap: 10px;

`
const Person__Box = styled(FlexBox)`
  width: calc(100% - 10px);
  border-radius: 10px;
  padding: 5px;
  border: 1px solid ${({theme}) => rgba(theme.color, 0.2)};
  flex-wrap: nowrap;
  background-color: ${({theme}) => theme.backgroundColor};

  @media (max-width: 800px) {
    flex-direction: column;
  }
`
const Person__Image = styled(Image)`
  height: 280px;
  width: 280px;
  flex-shrink: 0;
  object-fit: contain;
  background-color: transparent;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin: auto 0 auto 5px;
  @media (max-width: 800px) {
    width: 100%;
    margin: 0;
    height: 300px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`
const Person__Information__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 10px 20px;
  @media (max-width: 800px) {
    padding: 10px;
  }
`

const Person__Name = styled(Text)`
  font-size: 25px;
  margin-bottom: 2px;
  font-weight: bold;
`
const Person__Title = styled(Text)`
  font-size: 16px;
  margin-bottom: 8px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`

const Person__Description = styled(Text)`
  font-size: 18px;
`


export const Styled = {
    Person__Title,
    Person__Name,
    Person__Description,
    Person__Information__Holder,
    Person__Image,
    Person__Box,
    Team__Mates__Holder,
    Our__Team__Section__Title,
    Our__Team__Section__Holder
}