import MainPageNewsSeller from "./MainPageNewsSeller";
import UpcomingEvents from "./UpcomingEvents";
import FlexBox from "../../lib/UI/FlexBox";
import Sponsors from "../Sponsors";
import ImageGallery from "./ImageGallery";
import {useTranslation} from "react-i18next";
import usePageContent from "../../lib/PageContent";

const MainPage = () => {

    const {t} = useTranslation()

    const {imagesList} = usePageContent()
    return (
        <FlexBox
            style={{
                flexDirection: 'column',
                flexWrap: 'nowrap'
            }}
        >
            <ImageGallery
                list={imagesList}
            />
            <UpcomingEvents/>
            <MainPageNewsSeller/>
            <Sponsors
                isMainPage={true}
            />
        </FlexBox>
    )
}

export default MainPage