import {Styled} from "./OurLeague.styles"
import PageHolder from "../../lib/UI/PageHolder";
import {useTranslation} from "react-i18next";
import usePageContent from "../../lib/PageContent";

const OurLeague = () => {

    const {t} = useTranslation()

    const {ourLeague} = usePageContent()

    const {
        image,
        description,
        questions
    } = ourLeague


    return (
        <PageHolder>
            <Styled.Our__League__Holder>
                <Styled.League__Image
                    src={image}
                />
                <Styled.Our__League__Title>
                    {t('lbl_our_league')}
                </Styled.Our__League__Title>
                <Styled.Our__league__Description>
                    {description}
                </Styled.Our__league__Description>
                <Styled.Our__League__Whats__Sets_Us__Apart>
                    {t('lbl_what_sets_us_apart')}
                </Styled.Our__League__Whats__Sets_Us__Apart>
                <Styled.Our__League__Reasons__Holder>
                    {questions.map((item: any, index: number) => (
                        <>
                            <Styled.League__Section__Title>
                                {`${index + 1}. ${item.title}`}
                            </Styled.League__Section__Title>
                            <br/>
                            <Styled.League__Section__Answer>
                                {item.description}
                            </Styled.League__Section__Answer>
                            <br/>
                        </>
                    ))}
                    <Styled.League__Section__Answer>
                        {t('lbl_our_league_final_speech')}
                    </Styled.League__Section__Answer>
                </Styled.Our__League__Reasons__Holder>
            </Styled.Our__League__Holder>
        </PageHolder>
    )
}

export default OurLeague