import TextInput from "../../lib/UI/TextInput";
import TextArea from "../../lib/UI/TextArea";
import Button from "../../lib/UI/Button";
import {useTranslation} from "react-i18next";
import {Styled} from "./MessageForm.styles";
import {useRef, useState} from "react";
import {includes, isEmpty} from "lodash-es";
import emailjs from '@emailjs/browser';
import {faCheckCircle, faMedal} from "@fortawesome/free-solid-svg-icons";

const MessageForm = () => {

    const [form, setForm] = useState<any>({
        mail: '',
        name: "",
        message: '',
        invalidFields: [],
        isLoading: false,
        status: ''
    })

    const {t} = useTranslation()

    const formRef = useRef<any>();

    const validateFields = () => {
        const invalidFields: any = [];
        ['mail', 'name', 'message'].map((type: string) => {
            if (isEmpty(form[type])) {
                invalidFields.push(type)
            }
        })
        setForm((state: any) => ({
            ...state,
            invalidFields
        }))
        return invalidFields
    }

    const onEmailChangeHandler = (event: any) => {
        setForm((state: any) => ({
            ...state,
            mail: event.target.value,
            invalidFields: state.invalidFields.filter((item: string) => item !== 'mail')
        }))
    }

    const onNameChangeHandler = (event: any) => {
        setForm((state: any) => ({
            ...state,
            name: event.target.value,
            invalidFields: state.invalidFields.filter((item: string) => item !== 'name')
        }))
    }

    const onMessageChangeHandler = (event: any) => {
        setForm((state: any) => ({
            ...state,
            message: event.target.value,
            invalidFields: state.invalidFields.filter((item: string) => item !== 'name')
        }))
    }

    const onSubmit = () => {
        const isValid = isEmpty(validateFields())
        if (isValid) {
            setForm((state: any) => ({
                ...state,
                isLoading: true
            }))
            emailjs.send('acl_service', 'template_uk6q7if', {
                from_name: `${form.name} , ${form.mail}`,
                message: form.message,
            }, {publicKey: 'Fg9VVc1SVstOt8Dud'})
                .catch((e: any) => {
                    setForm((state: any) => ({
                        ...state,
                        isLoading: false,
                        status: 'Error'
                    }))
                }).finally(() => {
                setForm((state: any) => ({
                    ...state,
                    isLoading: false,
                    status: 'OK'
                }))
            })
        }
    }


    return (
        <Styled.Message__Form__Holder
            ref={formRef}
        >
            {(form.isLoading || form.status === 'OK') && (
                <Styled.Status__Holder>
                    {form.isLoading && (
                        <Styled.Loader__Icon
                            icon={faMedal}
                        />
                    )}
                    {form.status === 'OK' && (
                        <Styled.Status__Holder__Icon>
                            <Styled.OK__Status__Icon
                                icon={faCheckCircle}
                            />
                            <Styled.OK__Status__Text>
                                {t('lbl_email_sent')}
                            </Styled.OK__Status__Text>
                        </Styled.Status__Holder__Icon>
                    )}
                </Styled.Status__Holder>
            )}
            <TextInput
                error={includes(form.invalidFields, 'mail')}
                value={form.mail}
                name={'user_email'}
                onChange={onEmailChangeHandler}
                placeholder={t('lbl_write_your_email')}
                showIcon={false}
                style={{
                    backgroundColor: 'black',
                    maxWidth: '400px',
                    flexGrow: '1'
                }}
            />
            <br/>
            <TextInput
                error={includes(form.invalidFields, 'name')}
                value={form.name}
                onChange={onNameChangeHandler}
                name={'user_name'}
                placeholder={t('lbl_mail_title')}
                showIcon={false}
                style={{
                    backgroundColor: 'black',
                    maxWidth: '400px',
                    flexGrow: '1'
                }}
            />
            <br/>
            <TextArea
                isError={includes(form.invalidFields, 'name',)}
                value={form.message}
                onChange={onMessageChangeHandler}
                name={'message'}
                placeholder={t('lbl_write_ur_message')}
                style={{
                    maxWidth: '800px',
                    minHeight: '300px',
                    width: "100%"
                }}
            />
            <br/>
            <Button
                onClick={onSubmit}
                label={t('lbl_send')}
                style={{
                    marginLeft: 'auto',
                    padding: '0 10px',
                    minWidth: '125px',
                    marginRight: '20px'
                }}
            />
        </Styled.Message__Form__Holder>
    )
}

export default MessageForm