const MAIN_PAGE = '/'
const OUR_TEAM = '/Nasz-Zespół'
const OUR_LEAGUE = 'Nasza-Liga'
const CONTACT = '/Kontakt'
const TERMS = '/Regulamin'
const RANKING = '/Ranking'
const SPONSORS = '/Sponsorzy-Partnerzy'
const JOIN_OUR_TEAM = '/Dołącz-Do-Zespołu'
const SECOND__LEAGUE = '/Amateur-Combat-League-2'
const YOUR_CARRIER = '/Twoja-Karriera-w-ACL'
const IMAGES_FROM_EVENTS = '/Zdjęcia-z-ACL'

export {
    IMAGES_FROM_EVENTS,
    YOUR_CARRIER,
    SECOND__LEAGUE,
    OUR_LEAGUE,
    RANKING,
    MAIN_PAGE,
    OUR_TEAM,
    CONTACT,
    TERMS,
    SPONSORS,
    JOIN_OUR_TEAM
}