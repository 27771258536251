import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../../lib/UI/Text";
import {rgba} from "polished";
import Image from "../../lib/UI/Image";

const Header__Holder = styled(FlexBox)`
  width: 100vw;
  position: fixed;
  z-index: 100;
  background-color: ${({theme}) => theme.backgroundColor};
  border-bottom: 1px solid ${({theme}) => theme.color};
`
const Menu__Icon = styled(FontAwesomeIcon)`
  height: 28px;
  width: 28px;
  margin: auto 0 auto auto;
  color: ${({theme}) => theme.color};;
  cursor: pointer;
`
const Wide__Screen__Content = styled(FlexBox)`
  padding: 10px;
  display: flex;
  @media (max-width: 750px) {
    display: none;
  }
`
const Small__Screen__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  display: none;
  padding: 10px;
  @media (max-width: 750px) {
    display: flex;
  }
`

const Header__Drawer__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  min-width: 400px;
  padding-bottom: 15px;
  @media (max-width: 400px) {
    min-width: 100vw;
    width: 100%;
  }
`
const Drawer__Section__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Drawer__Section__Title = styled(Text)`
  padding: 10px 10px 0 10px;
  font-size: 20px;
  font-weight: bold;
`
const Drawer__Lower__Section__Option = styled(Text)`
  font-size: 19px;
  width: calc(100% - 20px);
  padding: 15px 10px;
  border-radius: 10px;
  background-color: ${({theme}) => rgba(theme.color, 0.2)};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
const Drawer__Lower__Section__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-top: 15px;
  padding: 0 10px;
  gap: 8px;
`
const Drawer__Language__Settings__Holder = styled(FlexBox)`
  flex-direction: initial;
  gap: 20px;
  justify-content: space-between;
  margin: 0 auto;
`
const Language__Icon = styled(Image)`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
`
const Language__Holder = styled(FlexBox)`
  flex-direction: column;
  cursor: pointer;
`
const Language__Label = styled(Text)`
  text-align: center;
  margin-top: 8px;
  font-weight: bold;
`
const Drawer__Section__Icon = styled(FontAwesomeIcon)`
  height: 22px;
  width: 22px;
  margin-right: 10px;
`
const Header__Options__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  margin-left: 40px;
  margin-top: auto;
  margin-bottom: auto;
  gap: 20px;
`
const Navigation__Option = styled(Text)<{ isCustom: boolean }>`
  font-size: 18px;
  font-weight: bold;
  cursor: ${({isCustom}) => isCustom ? 'pointer' : 'default'};
`
const Navigation__Drawer__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  min-width: 380px;
  padding: 10px;
  @media (max-width: 400px) {
    min-width: 100vw;
  }
`
const Navigation__Drop__Down = styled(FlexBox)`
  flex-direction: column;
  min-width: 200px;
  padding: 5px;
  gap: 10px;
`
const Navigation__Drop__Down__Option = styled(Text)`
  font-size: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

`
const Navigation__Drop__Down__Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`
const Language__Wide__Screen__Options = styled(FlexBox)`
  flex-direction: column;
  min-width: 200px;
`
const language__Label = styled(Text)`
  font-size: 18px;
  font-weight: bold;
`
const Wide__Screen__Lang__Option = styled(FlexBox)`
  flex-wrap: nowrap;
  padding: 5px;
  cursor: pointer;

  &:hover ${language__Label} {
    text-decoration: underline;
  }

`
const Country__Flag = styled(Image)`
  width: 36px;
  object-fit: cover;
  height: 18px;
  margin-top: 4px;
  margin-right: 8px;
`


export const Styled = {
    language__Label,
    Country__Flag,
    Wide__Screen__Lang__Option,
    Language__Wide__Screen__Options,
    Navigation__Drawer__Content__Holder,
    Navigation__Drop__Down__Option,
    Navigation__Drop__Down,
    Navigation__Drop__Down__Icon,
    Navigation__Option,
    Header__Options__Holder,
    Small__Screen__Holder,
    Drawer__Section__Icon,
    Language__Label,
    Language__Holder,
    Language__Icon,
    Drawer__Language__Settings__Holder,
    Drawer__Lower__Section__Option,
    Drawer__Section__Title,
    Drawer__Section__Holder,
    Header__Holder,
    Header__Drawer__Content__Holder,
    Wide__Screen__Content,
    Menu__Icon,
    Drawer__Lower__Section__Holder
}