import {Styled} from "./Contact.styles"
import {useTranslation} from "react-i18next";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import usePageContent from "../../lib/PageContent";
import MessageForm from "../../Components/MessageForm";
import PageHolder from "../../lib/UI/PageHolder";

const Contact = () => {

    const {t} = useTranslation()

    const {contactValues, socialMedia} = usePageContent()

    const icons: any = {
        'phone': faPhone,
        'mail': faEnvelope
    }

    const prefix: any = {
        'phone': 'tel:',
        'mail': 'mailto:'
    }

    return (
        <Styled.Contact__Page>
            <Styled.Contact__Page__Title>
                {t("lbl_contact_us_full")}
            </Styled.Contact__Page__Title>
            {Object.keys(contactValues).map((section: string) => {
                return (
                    <>
                        <Styled.Contact__Page__Contact__Type>
                            {t(`lbl_by_${section}`)}
                        </Styled.Contact__Page__Contact__Type>
                        <Styled.Contact__Page__Options__Holder
                            href={`${prefix[section]}${contactValues[section]}`}
                        >
                            <Styled.Contact__Page__Type__Icon
                                icon={icons[section]}
                            />
                            {contactValues[section]}
                        </Styled.Contact__Page__Options__Holder>
                    </>
                )

            })}
            <Styled.Contact__Page__Contact__Type>
                {t(`lbl_by_other`)}
            </Styled.Contact__Page__Contact__Type>
            {socialMedia.map((social: any) => (
                <Styled.Contact__Page__Options__Holder
                    href={social.link}
                    target={'_blank'}
                >
                    <Styled.Contact__Page__Type__Icon
                        icon={social.icon}
                    />
                    {social.label}
                </Styled.Contact__Page__Options__Holder>
            ))}
            <Styled.Contact__Page__Contact__Type>
                {t(`lbl_or_write_us_email`)}
            </Styled.Contact__Page__Contact__Type>
            <PageHolder>
                <MessageForm/>
            </PageHolder>
        </Styled.Contact__Page>
    )
}

export default Contact