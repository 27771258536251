import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import Text from "../../../lib/UI/Text";

const Upcoming__Events__Holder = styled(FlexBox)`
  border-top: 1px solid ${({theme}) => theme.color};
  border-bottom: 1px solid ${({theme}) => theme.color};
  background-image: url("/klatka-mma-.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 65px 0;
  width: 100%;
`
const Section__Label = styled.h2`
  font-size: 29px;
  margin: 0;
  color: ${({theme}) => theme.color};
  font-weight: bold;
  text-align: center;
  padding: 0 10px;
  padding-bottom: 20px;
`
const Upcoming__Event__Date = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  color: ${({theme}) => theme.color};
  text-align: center;
  margin-bottom: 12px;
`

const Upcoming__Event__Main__Info = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  color: ${({theme}) => theme.color};
  text-align: center;
`
const Event__Title = styled(Text)`
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 25px;
  color: ${({theme}) => theme.color};
  text-align: center;
`
const Event__Action__Buttons = styled(FlexBox)`
  width: 100%;
  margin-top: 30px;
  gap: 10px;
  align-content: center;
  justify-content: center;
`
const Event__Action__Eclipse = styled(Text)`
  padding: 10px 15px;
  font-size: 19px;
  border-radius: 25px;
  color: ${({theme}) => theme.color};
  border: 1px solid ${({theme}) => theme.color};
  cursor: pointer;
  transition: 200ms;

  &:hover {
    color: ${({theme}) => theme.backgroundColor};
    background-color: ${({theme}) => theme.color};
    border-color: ${({theme}) => theme.backgroundColor};;
  }

`
const Upcoming__Event__Holder = styled(FlexBox)`
  flex-direction: column;
  background-color: ${({theme}) => theme.backgroundColor};
  padding: 25px 15px;
  border-radius: 15px;
  margin: 0 10px;
  border: 2px solid ${({theme}) => theme.color};
`

export const Styled = {
    Upcoming__Event__Date,
    Upcoming__Event__Holder,
    Event__Action__Eclipse,
    Event__Action__Buttons,
    Event__Title,
    Upcoming__Event__Main__Info,
    Upcoming__Events__Holder,
    Section__Label
}