import {Styled} from "./Header.styles";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import HeaderDrawer from "./HeaderDrawer";
import {useNavigate} from "react-router-dom";
import {MAIN_PAGE} from "../../Routes";
import PageHolder from "../../lib/UI/PageHolder";
import FlexBox from "../../lib/UI/FlexBox";
import usePageContent from "../../lib/PageContent";
import Image from "../../lib/UI/Image";
import Tooltip from "../../lib/UI/Tooltip";
import NavigationToolTipContents from "./NavigationToolTipContent";

const Header = () => {

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const navigate = useNavigate()

    const {
        headerOptions,
        language,
        selectedLanguage,
        handleAnimation
    } = usePageContent()

    const onLogoClickHandler = () => {
        handleAnimation()
        setTimeout(() => {
            window.scroll(0, 0)
            navigate(MAIN_PAGE)
        }, 400)
    }

    const languageOption = (
        <Styled.Language__Wide__Screen__Options>
            {language.map((lang: any) => (
                <Styled.Wide__Screen__Lang__Option
                    onClick={() => {
                        localStorage.setItem('app-lang', lang.code)
                        window.location.reload()
                    }}
                >
                    <Styled.Country__Flag
                        src={lang.image}
                    />
                    <Styled.language__Label>
                        {lang.label}
                    </Styled.language__Label>
                </Styled.Wide__Screen__Lang__Option>
            ))}
        </Styled.Language__Wide__Screen__Options>
    )

    const wideScreenContent = (
        <Styled.Wide__Screen__Content>
            <Image
                onClick={onLogoClickHandler}
                src={window.location.origin + '/LOGO_(ACL).png'}
                style={{
                    backgroundColor: 'transparent',
                    height: '50px',
                    cursor: 'pointer'
                }}
            />
            <Styled.Header__Options__Holder>
                {headerOptions.map((option: any) => (
                    <Tooltip
                        disabled={option?.isCustom}
                        trigger={'onclick, mouseenter'}
                        key={`navigation_bar_option_${option.label}`}
                        interactive={true}
                        arrow={false}
                        placement={'bottom-start'}
                        content={<NavigationToolTipContents list={option.underSections}/>}
                    >
                        <Styled.Navigation__Option
                            onClick={option?.customAction}
                            isCustom={!!option?.isCustom}
                        >
                            {option.title}
                        </Styled.Navigation__Option>
                    </Tooltip>
                ))}
            </Styled.Header__Options__Holder>
            <Tooltip
                trigger={'onclick, mouseenter'}
                interactive={true}
                arrow={false}
                placement={'bottom-start'}
                content={languageOption}
            >
                <FlexBox
                    style={{
                        margin: "auto 0 auto auto"
                    }}
                >
                    <Styled.Language__Icon
                        src={selectedLanguage.image}
                        style={{
                            height: '45px',
                            width: '45px',

                        }}
                    />
                </FlexBox>
            </Tooltip>
        </Styled.Wide__Screen__Content>
    )

    const smallScreen = (
        <Styled.Small__Screen__Holder>
            <Image
                onClick={onLogoClickHandler}
                src={window.location.origin + '/LOGO_(ACL).png'}
                style={{
                    backgroundColor: 'transparent',
                    height: '50px',
                    cursor: 'pointer'
                }}
            />

            <Styled.Menu__Icon
                onClick={() => setIsOpen(true)}
                icon={faBars}
            />
        </Styled.Small__Screen__Holder>
    )


    return (
        <>
            <HeaderDrawer
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
            />
            <FlexBox
                style={{
                    height: '71px'
                }}>

            </FlexBox>
            <Styled.Header__Holder>
                <PageHolder>
                    {wideScreenContent}
                    {smallScreen}
                </PageHolder>
            </Styled.Header__Holder>
        </>
    )
}

export default Header