import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Text from "../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Footer__Background = styled(FlexBox)`
  width: 100%;
  padding: 25px 0;
  background-color: ${({theme}) => theme.backgroundColor};;
  border-top: 1px solid ${({theme}) => theme.color};
`
const Social__Media__Title = styled(Text)`
  font-size: 19px;
  color: ${({theme}) => theme.color};
  font-weight: bold;
  text-align: center;
`
const Social__Media__Holder = styled(FlexBox)`
  gap: 10px 15px;
  margin: 15px auto;
`
const Social__Media = styled.a`
  font-size: 18px;
  cursor: pointer;
  color: ${({theme}) => theme.color};

  &:hover {
    text-decoration: underline;
  }
`
const Social__Media__Icon = styled(FontAwesomeIcon)`
  height: 19px;
  width: 19px;
  margin-right: 4px;
`


export const Styled = {
    Social__Media__Icon,
    Social__Media,
    Social__Media__Holder,
    Social__Media__Title,
    Footer__Background
}