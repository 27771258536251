import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Text from "../../lib/UI/Text";

const Your__Carrier__Content = styled(FlexBox)`
  flex-direction: column;
  min-height: calc(100vh - 80px);
`
const Your__Carrier__Title = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  padding: 25px 10px;
  text-align: center;
`
const Description__Content = styled(Text)`
  padding: 0 10px;
  font-size: 18px;
`
const Step__Positioner = styled(FlexBox)<{ isEven: boolean }>`
  position: relative;
  flex-wrap: nowrap;
`
const Step__Line = styled(FlexBox)<{ isLast: boolean }>`
  border-right: 2px solid ${({theme}) => theme.color};
  min-height: ${({isLast}) => isLast ? '0px' : "100px"};
`
const Step__Bullet = styled(FlexBox)`
  margin-left: -10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.color};
  margin-top: 20px;
`
const Step__Text = styled(Text)`
  font-size: 21px;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 15px;
`
const Steps__Line__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 0 20px;

`


export const Styled = {
    Steps__Line__Holder,
    Step__Text,
    Step__Bullet,
    Step__Line,
    Step__Positioner,
    Description__Content,
    Your__Carrier__Title,
    Your__Carrier__Content
}