import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Image from "../../lib/UI/Image";
import Text from "../../lib/UI/Text";

const Event__Images__Page = styled(FlexBox)`
  padding: 20px 0;
  min-height: calc(100vh - 110px);
`
const Event__Option = styled.a`
  position: relative;
  display: flex;
  width: 33%;
  aspect-ratio: 1;
  background-color: grey;

  @media (max-width: 800px) {
    width: 50%;
  }
`
const Event__Image = styled(Image)`
  width: 100%;
  height: 100%;
  -webkit-filter: grayscale(20%);
  object-fit: cover;
`
const Event__Title__Holder = styled(FlexBox)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`
const Event__Title = styled(Text)`
  font-size: 30px;
  text-shadow: 3px 3px black;
  margin: auto;
  font-weight: bold;
`


export const Styled = {
    Event__Title,
    Event__Title__Holder,
    Event__Image,
    Event__Option,
    Event__Images__Page
}