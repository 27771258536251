import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import {rgba} from "polished";
import Image from "../../../lib/UI/Image";
import Text from "../../../lib/UI/Text";

const Section__Label = styled.h2`
  font-size: 29px;
  margin: 0;
  color: ${({theme}) => theme.color};
  font-weight: bold;
  text-align: center;
  padding: 35px 10px;
`
const News__Seller__Content__Holder = styled(FlexBox)`
  position: relative;
  flex-wrap: nowrap;
  width: calc(100% - 20px);
  padding: 0 10px;
  overflow: hidden;

`
const News__Component = styled(FlexBox)`
  flex-direction: column;
  background-color: ${({theme}) => theme.backgroundColor};
  flex-grow: 1;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => rgba(theme.color, 0.3)};
  border-radius: 15px;
  min-height: 675px;
  height: 100%;
`
const News__Image = styled(Image)`
  width: 100%;
  height: 275px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: ${({theme}) => theme.backgroundColor};
  border-bottom: 1px solid ${rgba('#fff', 0.3)};
`
const News__Title = styled(Text)`
  font-size: 19px;
  font-weight: bold;
  padding: 10px;
`
const News__Content__Text = styled(Text)`
  font-size: 18px;
  padding: 10px;
`
const News__Seller__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
`


export const Styled = {
    News__Seller__Holder,
    News__Content__Text,
    News__Title,
    News__Image,
    News__Component,
    Section__Label,
    News__Seller__Content__Holder
}