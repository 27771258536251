import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Text from "../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Terms__Holder = styled(FlexBox)`
  min-height: calc(100vh - 58px);
  padding: 10px;
  flex-direction: column;
  gap: 20px;
`
const Terms__Option = styled.a`
  display: flex;
  text-decoration: none;
  box-sizing: border-box;
  padding: 25px;
  border-radius: 5px;
  margin: 0 auto;
  max-width: 400px;
  border: 1px solid ${({theme}) => theme.color};

`

const Terms__Title = styled(Text)`
  font-weight: bold;
  font-size: 29px;
  text-align: center;
  padding: 20px 10px;
`
const Pdf__Icon = styled(FontAwesomeIcon)`
  height: 25px;
  width: 25px;
  margin: auto 0;
  color: ${({theme}) => theme.color};
`
const Pdf__Title = styled(Text)`
  font-size: 22px;
  margin-left: 15px;
`

export const Styled = {
    Pdf__Title,
    Pdf__Icon,
    Terms__Title,
    Terms__Option,
    Terms__Holder
}