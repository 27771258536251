import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Text from "../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Contact__Page = styled(FlexBox)`
  flex-direction: column;
  width: calc(100%);
  min-height: calc(100vh - 88px);
  margin-bottom: 25px;
`
const Contact__Page__Title = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  padding: 25px 10px;
  text-align: center;
`
const Contact__Page__Options__Holder = styled.a`
  width: calc(100% - 20px);
  font-size: 24px;
  color: ${({theme}) => theme.color};
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 auto;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`
const Contact__Page__Contact__Type = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  padding: 15px 10px;
  width: calc(100% - 20px);
  text-align: center;
`
const Contact__Page__Type__Icon = styled(FontAwesomeIcon)`
  height: 20px;
  width: 20px;

  margin-right: 10px;
`
const Contact__Form__Holder = styled(FlexBox)`
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`


export const Styled = {
    Contact__Form__Holder,
    Contact__Page__Type__Icon,
    Contact__Page__Contact__Type,
    Contact__Page,
    Contact__Page__Options__Holder,
    Contact__Page__Title
}