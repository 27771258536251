import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Image from "../../lib/UI/Image";
import {rgba} from "polished";
import Text from "../../lib/UI/Text";

const First__Event__Holder = styled(FlexBox)`
  flex-direction: column;
  min-height: calc(100vh - 58px);
  margin-bottom: 30px;
`
const First__Event__Main__Image = styled(Image)`
  width: 100%;
  object-fit: contain;
  max-height: 50vw;
  background-color: ${({theme}) => theme.backgroundColor};
  border-bottom: 1px solid ${rgba('#fff', 0.3)};

  @media (min-width: 1100px) {
    max-height: 550px;
  }
`
const First__Event__Name__Title = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  padding: 25px 10px;
  text-align: center;
`
const First__Event__Content = styled(Text)`
  padding: 0 10px;
  font-size: 18px;
`


export const Styled = {
    First__Event__Content,
    First__Event__Name__Title,
    First__Event__Main__Image,
    First__Event__Holder
}