import PageHolder from "../../../lib/UI/PageHolder";
import {useTranslation} from "react-i18next";
import {Styled} from "./MainPageNewsSeller.styles";
import {SwiperContainer, SwiperSlide} from "../../../lib/UI/Swiper";
import {useRef, useState} from "react";
import News from "./News";
import {isMobileOnly} from "react-device-detect";
import usePageContent from "../../../lib/PageContent";
import {NextButton, PrevButton} from "../../../lib/UI/Swiper/Buttons";


const MainPageNewsSeller = () => {

    const [sliderState, setSliderState] = useState({progress: 0, index: 0})

    const {t} = useTranslation()

    const swiperRef = useRef<any>(null)

    const {newsList} = usePageContent()

    const onActiveIndexChange = (swiper: any) => {
        setSliderState((state: any) => ({
            ...state,
            progress: swiper.progress,
            index: swiper?.activeIndex,
        }));
    };

    const onPrevButtonClick = (event: any) => {
        event.stopPropagation()
        swiperRef.current.swiper.slidePrev();
    }

    const onNextButtonClick = (event: any) => {
        event.stopPropagation()
        swiperRef.current.swiper.slideNext();
    }

    return (
        <Styled.News__Seller__Holder>
            <PageHolder>
                <Styled.Section__Label>
                    {t('lbl_news')}
                </Styled.Section__Label>
                <Styled.News__Seller__Content__Holder>
                    {sliderState.index !== 0 && (
                        <PrevButton
                            onClick={onPrevButtonClick}
                        />
                    )}
                    <SwiperContainer
                        onActiveIndexChange={onActiveIndexChange}
                        ref={swiperRef}
                    >
                        {newsList.map((item: any) => (
                            <SwiperSlide
                                key={`key_${item.title}`}
                                style={{
                                    maxWidth: isMobileOnly ? 'calc(100vw)' : 'calc(400px)',
                                    margin: isMobileOnly ? '0' : '0 10px',
                                    height: '100%',
                                    minHeight: '100%'
                                }}
                            >
                                <News
                                    item={item}
                                />
                            </SwiperSlide>
                        ))}
                    </SwiperContainer>
                    {sliderState.progress < 1 && (
                        <NextButton
                            onClick={onNextButtonClick}
                        />
                    )}
                </Styled.News__Seller__Content__Holder>
            </PageHolder>
        </Styled.News__Seller__Holder>
    )
}

export default MainPageNewsSeller