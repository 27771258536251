import {Styled} from "./Ranking.styles"
import {useTranslation} from "react-i18next";
import {faRankingStar} from "@fortawesome/free-solid-svg-icons";

const Ranking = () => {

    const {t} = useTranslation()

    return (
        <Styled.Ranking__Holder>
            <Styled.Ranking__Icon
                icon={faRankingStar}
            />

            <Styled.Ranking__Coming__Soon__Title>
                {t('lbl_ranking_coming_soon')}
            </Styled.Ranking__Coming__Soon__Title>
        </Styled.Ranking__Holder>
    )
}

export default Ranking