import PageHolder from "../../lib/UI/PageHolder";
import {Styled} from "./JoinUs.styles";
import {useTranslation} from "react-i18next";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import usePageContent from "../../lib/PageContent";
import {CONTACT} from "../../Routes";
import {useNavigate} from "react-router-dom";

const JoinUs = () => {

    const {t} = useTranslation()

    const navigate = useNavigate()

    const {sponsorMail, handleAnimation} = usePageContent()


    const onContactClickHandler = () => {
        handleAnimation()
        setTimeout(() => {
            window.scroll(0, 0)
            navigate(CONTACT)
        }, 400)
    }

    return (
        <PageHolder>
            <Styled.Join__Us__Holder>
                <Styled.Join_Us>
                    {t('lbl_develop_with_us')}
                </Styled.Join_Us>
                <Styled.Invite__Text>
                    {t('lbl_develop_with_us_desc')}
                </Styled.Invite__Text>
                <Styled.How__To__Join>
                    {t('lbl_write_us_email')}
                </Styled.How__To__Join>
                <Styled.Mail__Holder
                    href={`mailto:${sponsorMail}`}
                >
                    <Styled.Mail__Icon
                        icon={faEnvelope}
                    />
                    {sponsorMail}
                </Styled.Mail__Holder>
                <Styled.How__To__Join>
                    {t('lbl_more_contact_options')}
                </Styled.How__To__Join>
                <Styled.Kontakt__Holder
                    onClick={onContactClickHandler}
                >
                    {t('lbl_contact_us')}
                </Styled.Kontakt__Holder>
            </Styled.Join__Us__Holder>
        </PageHolder>
    )
}
export default JoinUs