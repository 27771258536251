import {Styled} from "./Terms.styles"
import PageHolder from "../../lib/UI/PageHolder";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

const Terms = () => {

    const {t} = useTranslation()

    const termsOptions = [
        {
            link: 'Regulamin_ACL.pdf',
            title: t('lbl_terms_acl')
        },
        {
            link: 'Zgoda_nieletni_ACL.pdf',
            title: t('lbl_consent_from_parents')
        },
        {
            link: 'Oswiadczenie_dorośli_ACL_pdf.pdf',
            title: t('lbl_consent_for_adults')
        },
    ]

    return (
        <PageHolder>
            <Styled.Terms__Title>
                {t('lbl_terms')}
            </Styled.Terms__Title>
            <Styled.Terms__Holder>
                {termsOptions.map((option: any) => (
                    <Styled.Terms__Option href={option.link} download={true}>
                        <Styled.Pdf__Icon
                            icon={faFilePdf}
                        />
                        <Styled.Pdf__Title>
                            {option.title}
                        </Styled.Pdf__Title>
                    </Styled.Terms__Option>
                ))}
            </Styled.Terms__Holder>
        </PageHolder>
    )
}

export default Terms