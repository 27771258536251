import {Styled} from "./YourCarrier.styles"
import {useTranslation} from "react-i18next";
import PageHolder from "../../lib/UI/PageHolder";

const YourCarrier = () => {

    const {t} = useTranslation()

    const carrier = [
        '3 Walki w pierwszym kroku',
        'Udzial w turnieju OFS',
        'Walka finałowa w formule Semi - Pro',
        'Zwycięstwo w rankingu federacji ACL danej kategorii',
        'Podpisanie kontraktu z grupą managerska ',
        'Walka zawodowa'
    ]


    return (
        <Styled.Your__Carrier__Content>
            <Styled.Your__Carrier__Title>
                {t('lbl_your_carrier_with_acl')}
            </Styled.Your__Carrier__Title>
            <PageHolder>
                <Styled.Description__Content>
                    {t('lbl_your_carrier_desc').split('<BREAK>').map((content: string) => (<>{content}<br/><br/></>))}
                </Styled.Description__Content>
                <Styled.Steps__Line__Holder>
                    {carrier.map((step: string, index: number) => {
                        const isLast = carrier.length - 1 === index
                        const isEven = index % 2 === 1

                        return (
                            <Styled.Step__Positioner
                                isEven={isEven}
                            >
                                <Styled.Step__Line
                                    isLast={isLast}
                                />
                                <Styled.Step__Bullet/>
                                <Styled.Step__Text>
                                    {step}
                                </Styled.Step__Text>
                            </Styled.Step__Positioner>
                        )
                    })}
                </Styled.Steps__Line__Holder>
            </PageHolder>
        </Styled.Your__Carrier__Content>
    )
}

export default YourCarrier