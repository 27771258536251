import React, {useState} from "react";

export const ANIMATION__CONTEXT = React.createContext(false)

type AnimationContextProps = {
    children: React.ReactElement
}

const AnimationContext = (
    {
        children
    }: AnimationContextProps) => {

    const [isAnimationOn, setIsAnimationOn] = useState(false)

    return (
        <ANIMATION__CONTEXT.Provider value={[isAnimationOn, setIsAnimationOn] as any}>
            {children}
        </ANIMATION__CONTEXT.Provider>
    )
}

export default AnimationContext