import Drawer from "../../lib/UI/Drawer";
import {Styled} from "./Header.styles";
import {useNavigate} from "react-router-dom";
import usePageContent from "../../lib/PageContent";
import {useTranslation} from "react-i18next";
import {faAccessibleIcon} from "@fortawesome/free-brands-svg-icons";
import FlexBox from "../../lib/UI/FlexBox";

type HeaderDrawerProps = {
    isOpen: boolean
    onClose: any
}

const HeaderDrawer = (
    {
        isOpen,
        onClose
    }: HeaderDrawerProps
) => {

    const navigate = useNavigate()

    const {t} = useTranslation()

    const {
        headerOptions,
        language,
        handleAnimation
    } = usePageContent()

    return (
        <Drawer
            title={t('lbl_our_league')}
            onClose={onClose}
            position={'right'}
            isOpen={isOpen}
        >
            <Styled.Header__Drawer__Content__Holder>
                <Styled.Drawer__Section__Title
                    style={{
                        marginBottom: '15px'
                    }}

                >
                    {t('lbl_quick_access')}
                </Styled.Drawer__Section__Title>
                {headerOptions.map((section: any) => {
                    return (
                        <Styled.Drawer__Section__Holder>
                            {section?.isCustom ? (
                                <FlexBox
                                    style={{
                                        margin: '0 10px'
                                    }}
                                >
                                    <Styled.Drawer__Lower__Section__Option
                                        onClick={section?.customAction}
                                    >
                                        <Styled.Drawer__Section__Icon
                                            icon={section?.icon ?? faAccessibleIcon}
                                        />
                                        {section.title}
                                    </Styled.Drawer__Lower__Section__Option>
                                </FlexBox>
                            ) : (
                                <Styled.Drawer__Section__Title
                                >
                                    {section.title}
                                </Styled.Drawer__Section__Title>
                            )}
                            <Styled.Drawer__Lower__Section__Holder>
                                {section.underSections.map((lowerSection: any) => {
                                    return (
                                        <Styled.Drawer__Lower__Section__Option
                                            onClick={lowerSection?.action ? lowerSection.action : () => {
                                                handleAnimation()
                                                setTimeout(() => {
                                                    navigate(lowerSection.navigation)
                                                    window.scroll(0, 0)
                                                    onClose()
                                                }, 400)
                                            }}

                                        >
                                            <Styled.Drawer__Section__Icon
                                                icon={lowerSection?.icon ?? faAccessibleIcon}
                                            />
                                            {lowerSection.title}
                                        </Styled.Drawer__Lower__Section__Option>
                                    )
                                })}
                            </Styled.Drawer__Lower__Section__Holder>
                        </Styled.Drawer__Section__Holder>
                    )
                })}
                <Styled.Drawer__Section__Holder>
                    <Styled.Drawer__Section__Title>
                        {t('lbl_change_language')}
                    </Styled.Drawer__Section__Title>
                    <Styled.Drawer__Lower__Section__Holder>
                        <Styled.Drawer__Language__Settings__Holder>
                            {language.map((lang: any) => {

                                const changeLanguage = () => {
                                    localStorage.setItem('app-lang', lang.code)
                                    window.location.reload()
                                }

                                return (
                                    <Styled.Language__Holder
                                        onClick={changeLanguage}
                                    >
                                        <Styled.Language__Icon
                                            src={lang.image}
                                        />
                                        <Styled.Language__Label>
                                            {lang.label}
                                        </Styled.Language__Label>
                                    </Styled.Language__Holder>
                                )
                            })}

                        </Styled.Drawer__Language__Settings__Holder>
                    </Styled.Drawer__Lower__Section__Holder>
                </Styled.Drawer__Section__Holder>
            </Styled.Header__Drawer__Content__Holder>
        </Drawer>
    )
}
export default HeaderDrawer