import {Styled} from "./FirstEvent.styles"
import PageHolder from "../../lib/UI/PageHolder";
import usePageContent from "../../lib/PageContent";
import {useTranslation} from "react-i18next";
import ImageGallery from "../MainPage/ImageGallery";

const FirstEvent = () => {
    const {firstEvent} = usePageContent()

    const {t} = useTranslation()

    const {
        mainImage,
        objectImages
    } = firstEvent

    return (
        <Styled.First__Event__Holder>
            <PageHolder>
                <Styled.First__Event__Main__Image
                    src={mainImage}
                />
                <Styled.First__Event__Name__Title>
                    Gala Amateur Combat League 1
                </Styled.First__Event__Name__Title>
                <Styled.First__Event__Content>
                    {t('lbl_ac1_desc').split('<BREAK>').map((content: string) => (<>{content}<br/><br/></>))}
                </Styled.First__Event__Content>
                <Styled.First__Event__Name__Title>
                    {t('lbl_about_place')}
                </Styled.First__Event__Name__Title>
                <ImageGallery
                    hideDots={true}
                    list={objectImages}
                />
            </PageHolder>
        </Styled.First__Event__Holder>
    )
}

export default FirstEvent