import {Styled} from "./Footer.styles"
import PageHolder from "../../lib/UI/PageHolder";
import {useTranslation} from "react-i18next";
import usePageContent from "../../lib/PageContent";

const Footer = () => {

    const {t} = useTranslation()

    const {socialMedia} = usePageContent()

    return (
        <Styled.Footer__Background>
            <PageHolder>
                <Styled.Social__Media__Title>
                    {t('lbl_find_us_on')}
                </Styled.Social__Media__Title>
                <Styled.Social__Media__Holder>
                    {socialMedia.map((social: any) => (
                        <Styled.Social__Media
                            href={social.link}
                            target={'_blank'}
                        >
                            <Styled.Social__Media__Icon
                                icon={social.icon}
                            />
                            {social.label}
                        </Styled.Social__Media>
                    ))}
                </Styled.Social__Media__Holder>
            </PageHolder>
        </Styled.Footer__Background>
    )
}

export default Footer