import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Image from "../../lib/UI/Image";

const Animation__Navigation__Change = styled(FlexBox)<{ isAnimate: boolean }>`
  @keyframes coverPageAnimation {
    0% {
      top: 0px;
      bottom: 0px;
      right: 100vw;
      left: 0px;
    }
    28% {
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
    }

    72% {
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
    }
    100% {
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 100vw;
      display: none;
    }
  }
  transition: 500ms;
  position: fixed;
  z-index: 9999999;
  overflow: hidden;
  background-color: ${({theme}) => theme.backgroundColor};
  animation: ${({isAnimate}) => isAnimate && `coverPageAnimation 1s linear`};
`
const Animation__Logo = styled(Image)`
  width: 25vw;
  min-width: 25vw;
  max-height: calc(100vh);
  object-fit: scale-down;
  margin: auto;
`


export const Styled = {
    Animation__Logo,
    Animation__Navigation__Change
}