import {Route, Routes} from "react-router-dom";
import {
        CONTACT,
        IMAGES_FROM_EVENTS,
        JOIN_OUR_TEAM,
        MAIN_PAGE,
        OUR_LEAGUE,
        OUR_TEAM,
        RANKING,
        SECOND__LEAGUE,
        SPONSORS,
        TERMS,
        YOUR_CARRIER
} from "../Routes";
import MainPage from "./MainPage";
import OurTeam from "./OurTeam";
import React from "react";
import OurLeague from "./OurLeague";
import Contact from "./Contact";
import Sponsors from "./Sponsors";
import Ranking from "./Ranking";
import Terms from "./Terms";
import JoinUs from "./JoinUs";
import FirstEvent from "./FirstEvent";
import YourCarrier from "./YourCarrier";
import EventImages from "./EventImages";

const AllRoutes = () => {


    return (
        <Routes>
            <Route
                path={MAIN_PAGE}
                element={(<MainPage/>)}
            />
            <Route
                path={IMAGES_FROM_EVENTS}
                element={(<EventImages/>)}
            />
            <Route
                path={OUR_TEAM}
                element={(<OurTeam/>)}
            />
            <Route
                path={OUR_LEAGUE}
                element={<OurLeague/>}
            />
            <Route
                path={CONTACT}
                element={<Contact/>}
            />
            <Route
                path={SPONSORS}
                element={<Sponsors/>}
            />
            <Route
                path={RANKING}
                element={<Ranking/>}
            />
            <Route
                path={JOIN_OUR_TEAM}
                element={<JoinUs/>}
            />
            <Route
                path={TERMS}
                element={<Terms/>}
            />
            <Route
                path={SECOND__LEAGUE}
                element={<FirstEvent/>}
            />
            <Route
                path={YOUR_CARRIER}
                element={<YourCarrier/>}
            />
        </Routes>
    )
}

export default AllRoutes